.Wheel {
    position: relative;
    display: inline-block;

    margin-bottom: 0.1rem;
    margin-right: 0.3rem;

    height: 1rem;

    transition: 200ms;
}

.Item {
    position: absolute;
    transition: 200ms;
}

.Item.current {
    font-weight: bold;
}

.Item.other { opacity: 0; }
.Item.previous, .Item.next { opacity: 0.5; }

.Item.previous { transform: translateY(-1.5rem) rotate3d(1, 0, 0, -60deg); }
.Item.next { transform: translateY(1.5rem) rotate3d(1, 0, 0, 60deg); }