.Noise {
    position: fixed;

    top: 0;
    left: 0;

    z-index: 20;

    width: 100%;
    height: 100%;

    pointer-events: none;

    opacity: 0.3;
}

@media only screen and (pointer: coarse) {
    .Noise {
        display: none;
    }
}